@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Poppins;
    src: url("../fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: Poppins;
    src: url("../fonts/Poppins-SemiBold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: Poppins;
    src: url("../fonts/Poppins-Bold.ttf");
    font-weight: bold;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin-bottom: 10px;
    line-height: 1.7rem;
}

p:last-child {
    margin-bottom: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
