.progress {
    position: relative;
    height: 10px;
    width: 70%;
    border: 10px solid #fff;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: content-box;
}
.progressColor {
    width: 0px;
    height: 10px;
    border-radius: 15px;
    animation: progressAnim 4s infinite linear;
}
@keyframes progressAnim {
    0% {
        width: 0%;
    }
    25% {
        width: 50%;
    }
    50% {
        width: 75%;
    }
    75% {
        width: 85%;
    }
    100% {
        width: 100%;
    }
} ;
